import Center from '../components/Center';
import Navbar from '../components/Navbar';

const Home = () => {
  return (
    <>
      <Navbar />
      <Center>
        <h3>Nothing Here</h3>
      </Center>
    </>
  );
};

export default Home;
